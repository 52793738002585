<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('Show') }}</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pageOptions"
                      :clearable="false" class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('Entries') }}</label>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="filter" class="d-inline-block mr-1" :placeholder="$t('Search...')"/>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-overlay :show="!loaded" rounded="sm">
        <b-table
            ref="refaccountCampaignListTable"
            class="position-relative"
            responsive
            primary-key="id"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :filter="filter"
            @filtered="onFiltered"
            show-empty
            :empty-text="$t('No matching records found')">
          <template #cell(order_number)="data">
            #{{ data.item.order_number }}

          </template>

          <template #cell(created_at)="data">
            {{ data.item.created_at | moment }}

          </template>


          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
                :to="{ name: 'invoice-preview',params:{id:data.item.id}}"
                v-b-tooltip.hover
                :title="$t('Preview Invoice')"
                variant="gradient-success"
                icon="EyeIcon"
                size="sm"
                class="mr-2">
              {{ $t('Preview') }}
            </b-button>

            <b-button
                v-b-tooltip.hover
                :title="$t('Download Invoice')"
                variant="gradient-warning"
                icon="EyeIcon"
                size="sm"
                class="mr-2"
                @click="getPdf(data.item.id,data.item.order_number)"
            >
              {{ $t('Download') }}
            </b-button>


          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">
              {{ $t('Showing') }}
              <span v-if="items.length === 0"> {{ items.length }}</span>
              <span v-else-if="items.length > 0"> {{ perPage * (currentPage - 1) + (perPage ? 1 : 0) }}</span>
              {{ $t('to') }}
              <span v-if="items.length < perPage - 1"> {{ items.length }}</span>
              <span v-else-if="perPage * (currentPage) > items.length"> {{ items.length }}</span>
              <span v-else> {{ perPage * (currentPage - 1) + perPage }}</span>
              {{ $t('of') }} {{ items.length }} {{ $t('entries') }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import moment from 'moment'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    VBTooltip,

    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      loaded: false,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      tableColumns: [
        {
          key: 'created_at',
          sortable: true,
          label: this.$i18n.t('Date'),
        },
        {
          key: 'order_number',
          sortable: true,
          label: this.$i18n.t('invoice Number'),
        },
        {
          key: 'client.firm',
          sortable: true,
          label: this.$i18n.t('Company'),
        },
        {
          key: 'total_price',
          sortable: true,
          label: this.$i18n.t('Amount'),
        },
        {
          key: 'actions',
          label: this.$i18n.t('Actions')
        }
      ],
      items: [],
    }
  },

  mounted() {
    this.getItems()
  },
  filters: {
    moment: function (date) {
      return moment.parseZone(date).format('DD.MM.YYYY HH:mm')
    }
  },
  methods: {
    getPdf(id,invoice) {
      this.$http.get(`client/order/generate/${id}`,{responseType: 'arraybuffer'})
          .then(response => {
            let blob = new Blob([response.data], { type: 'application/pdf' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `invoice-${invoice}.pdf`
            link.click()
          })
          .catch(error => {
          })
    },

    getItems() {
      this.items = []
      this.loaded = false

      this.helperGetItems(`/client/orders`)
    },


    formatName(value, key, item) {
      if (item.firstname == null && item.name == null) return item.email

      if (!item.firstname) return item.name

      return item.firstname + ' ' + item.name

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
